import * as React from "react"
import { useEffect } from "react"

import Seo from "../components/seo"
import Layout from "../components/layout"
import Animations from "../animations/animations"

import "../sass/style.scss"

import Logo from '../images/logo-white.png'
import Graphic1Img from '../images/graphic-1.svg'
import Swash1Img from '../images/swash-1.png'
import Swash5Img from '../images/swash-5.png'

const IndexPage = () => {

  useEffect(() => {

    setTimeout(() => {
      window.scrollTo(0,0)
    }, 250)

    const target = document.getElementById("klaviyo-script-target")
    if ( target ) {
      const script = document.createElement("script");
      script.src = "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=SFSBWE";
      script.async = true;
      target.appendChild(script)
    }

  }, [])

  return (
    <Layout>

      <Seo/>

      <section className="landing mod-dark-blue landing-intro ani ani-fade-up ani-onload" id="landing">
        <div className="fw">
          <div className="landing-intro ani ani-fade-up ani-onload ani-delay-1">
            <img src={Logo} alt="Wonderpack Logo" className="landing-intro-logo" />
            <h3 className="mod-orange">e-commerce trading experts</h3>
            <a href="#cta" className="button ani ani-fade-left ani-onload ani-delay-2">Get in touch</a>
          </div>
        </div>
        <div className="swash swash-1">
          <img src={ Swash1Img } className="swash-bg" alt="" role="presentation" />
          <img src={Graphic1Img} className="swash-graphic" alt="" role="presentation"/>
        </div>
      </section>

      <section className="cta" id="cta">
        <div className="fw ani ani-fade-up ani-onload ani-delay-3">
          <div id="klaviyo-script-target"/>
          <div className="cta-form klaviyo-form-RF7sSA"/>
        </div>
      </section>

      <Animations/>

    </Layout>
  )
}

export default IndexPage
