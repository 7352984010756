import Anime from 'animejs/lib/anime.es.js'
import ScrollMonitor from 'scrollmonitor';
import { useEffect } from "react"

function Animations() {

  const animations = []
  const watchers = []
  const base = 800


  const fadeUp = {
    translateY: [15, 0],
    opacity: [0, 1],
    autoplay: false,
    easing: 'easeOutCubic',
    duration: base
  }

  const fadeLeft = {
    translateX: [15, 0],
    opacity: [0, 1],
    autoplay: false,
    easing: 'easeOutCubic',
    duration: base
  }

  const fadeRight = {
    translateX: [-15, 0],
    opacity: [0, 1],
    autoplay: false,
    easing: 'easeOutCubic',
    duration: base
  }

  const fadeIn = {
    opacity: [0, 1],
    autoplay: false,
    easing: 'easeOutCubic',
    duration: base
  }

  const scaleIn = {
    scale: [.9, 1],
    opacity: [0, 1],
    autoplay: false,
    easing: 'easeOutCubic',
    duration: base
  }

  // const loopFloat = {
  //   translateY: [
  //     { value: 0 },
  //     { value: 100 },
  //   ],
  //   duration: 4000,
  //   easing: 'cubicBezier(.4, 0, .6 ,1)',
  //   direction: 'alternate',
  //   loop: true
  // }


  const selectAnimationObject = (a) => {
    switch (true) {
      case a.classList.contains('ani-fade-up'):
        return fadeUp;
      case a.classList.contains('ani-fade-left'):
        return fadeLeft;
      case a.classList.contains('ani-fade-right'):
        return fadeRight;
      case a.classList.contains('ani-fade-in'):
        return fadeIn;
      case a.classList.contains('ani-scale-in'):
        return scaleIn;
      // case a.classList.contains('ani-loop-float'):
      //   return loopFloat;
      default:
        return fadeIn;
    }
  }

  const adjustAnimationTiming = (a) => {
    switch (true) {
      case a.classList.contains('ani-delay-1'):
        return base
      case a.classList.contains('ani-delay-2'):
        return base * 2
      case a.classList.contains('ani-delay-3'):
        return base * 3
      case a.classList.contains('ani-delay-4'):
        return base * 4
      case a.classList.contains('ani-delay-5'):
        return base * 5
      default:
        return 0
    }
  }

  useEffect(() => {

    if (window.matchMedia("(min-width: 1100px)").matches) {

      const scrollOffset = -Math.abs(window.innerHeight * 0.275);

      const anis = document.querySelectorAll('.ani');

      anis.forEach((a, i) => {

        let targetAnimation = selectAnimationObject(a);

        targetAnimation.delay = adjustAnimationTiming(a);
        targetAnimation.targets = a;

        if ( a.classList.contains('ani-onload') ) {
          targetAnimation.delay += base;
          animations.push( Anime(targetAnimation) );
          animations[i].play();
          return;
        }

        if ( a.classList.contains('ani-onscroll') ) {
          animations.push( Anime(targetAnimation) );
          watchers.push({
            watcher: ScrollMonitor.create(animations[i].animatables[0].target, scrollOffset),
            indexTarget: i
          });
        }

      })

      for ( let i in watchers ) {
        watchers[i].watcher.enterViewport(() => {
          if (!animations[watchers[i].indexTarget].completed) {
            animations[watchers[i].indexTarget].play()
          }
        })
      }

      // Custom Styles Animation
      var aniFeaturesCustom = document.querySelector('.ani-features-custom')

      var featuresAni = Anime({
        targets: '.flex-50.ani-features-el',
        opacity: [0, 1],
        scale: [0.5, 1],
        duration: 1250,
        easing: 'easeOutExpo',
        autoplay: false,
        delay: Anime.stagger(800, {grid: [2, 2], from: 'first'}),
      });

      if( aniFeaturesCustom ) {

        var stylesWatcher = ScrollMonitor.create(aniFeaturesCustom, -Math.abs(window.innerHeight * 0.5))

        stylesWatcher.enterViewport(()=>{
          if ( !featuresAni.completed ) {
            featuresAni.play();
          }
        })
      }

    }

  }, [])

  return false

}

export default Animations